import React from 'react'
import HomePageComponent from '../components/Home/HomePageComponent'
import { motion } from 'framer-motion'
import NavBarComponent from '../components/NavBarComponent'

const HomePage = () => {
    return (
        <motion.div 
        initial={{opacity:0}}
        animate = {{opacity:1}}
        exit={{opacity:0}}
        >
            <NavBarComponent />
            <HomePageComponent />

        </motion.div>
    )
}

export default HomePage