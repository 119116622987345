import React from 'react'
import NavBarComponent from '../components/NavBarComponent'
import { Container } from 'react-bootstrap'
import ContactUsContentComponent from '../components/ContactUs/ContactUsContentComponent'
import { motion } from 'framer-motion'

const ContactUs = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <NavBarComponent />
            <Container fluid>
                <ContactUsContentComponent />
            </Container>
        </motion.div>
    )
}

export default ContactUs