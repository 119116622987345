import { motion } from 'framer-motion'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Image from "react-bootstrap/Image";
import "../styles/effortsGlobal.scss";
import "../styles/global.scss";
import NavBarComponent from '../components/NavBarComponent';


const EffortsGlobal = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <NavBarComponent />
      <Container fluid className='fontBlack'>
        <Row>
          <Col sm={12} md={12} lg={3}>
            <Image src={process.env.PUBLIC_URL + '/Assets/EffortsGlobal/Efforts-1.png'} className="efforts-img" alt="Efforts-1" />
          </Col>
          <Col sm={12} md={12} lg={3}>
            <Image src={process.env.PUBLIC_URL + '/Assets/EffortsGlobal/Efforts-2.png'} className="efforts-img" alt="Efforts-2" />
          </Col>
          <Col sm={12} md={12} lg={3}>
            <Image src={process.env.PUBLIC_URL + '/Assets/EffortsGlobal/Efforts-3.png'} className="efforts-img" alt="Efforts-3" />
          </Col>
          <Col sm={12} md={12} lg={3}>
            <Image src={process.env.PUBLIC_URL + '/Assets/EffortsGlobal/Efforts-4.png'} className="efforts-img" alt="Efforts-4" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className='efforts-main-title'>Design <span className='spanAnd'>&</span> <span className='spanRed'>Create</span></p>
            <p className='efforts-content'>At EFFORTS, we Design & Create ensuring the fruition of our Visions. Specializing in Large Scale Redevelopment Projects, EFFORTS has also ventured into the creation of Uber Luxurious Designer Villas, across the evergreen belt of Karjat, Maharashtra.</p>
          </Col>
        </Row>
        <Row className='efforts-logo-row'>
          <Col sm={12} md={12} lg={4} className='efforts-logo-col'>
            <Image src={process.env.PUBLIC_URL + '/Assets/EffortsGlobal/EffortsGlobalLogo.png'} className="efforts-logo" alt="Efforts-global-Logo" />
            <p className='efforts-below-logo-text'>Click here to visit www.effortsGlobal.com</p>
          </Col>
        </Row>
        
      </Container>
    </motion.div>
  )
}

export default EffortsGlobal