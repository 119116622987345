import { motion } from 'framer-motion';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../styles/miaHouseOfDesign.scss";
import "../styles/global.scss";
import Image from "react-bootstrap/Image";
import NavBarComponent from '../components/NavBarComponent';

const MIAHouseOfDesign = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <NavBarComponent />
      <Container fluid className='fontBlack'>
        <Row>
          <Col lg={4}>
            <Image src={process.env.PUBLIC_URL + '/Assets/MiaHouseOfDesign/Mia-house-of-design-1.png'} className="mia-img mia-img-1" alt="Mia-house-of-design-1" />
          </Col>
          <Col sm={12} md={12} lg={4}>
            <Image src={process.env.PUBLIC_URL + '/Assets/MiaHouseOfDesign/Mia-house-of-design-2.png'} className="mia-img" alt="Mia-house-of-design-2" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className='mia-main-title'>Design your Present to <span className='spanRed'>Plan your Future</span></p>
            <p className='mia-content'>As an accolade, MIA House of Design is proudly associated with one of the First Platinum-Vastu Proposals in India, located at Walkeshwar, Mumbai.</p>
            <br />
            <p className='mia-content'>Under our promoters' guidance, MIA is known for adopting a "Psychinterior" approach towards all our offerings. The influence of Interior Spaces, Lights, Forms, Textures, Colors, etc. on a user's Psychology is studied and carefully housed within all our proposals.</p>
          </Col>
        </Row>
        <Row className='mia-logo-row'>
          <Col sm={12} md={12} lg={4} className='mia-logo-col'>
            <Image src={process.env.PUBLIC_URL + '/Assets/MiaHouseOfDesign/MiaHouseOfDesignLogo.png'} className="mia-logo" alt="Mia-house-of-design-Logo" />
            <p className='mia-below-logo-text'>Click here to visit www.miaHouseOfDesign.com</p>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default MIAHouseOfDesign;
