export interface AIProject {
  name: string | null;
  place: string | null;
  city: string | null;
  country: string | null;
  cImageUrl: string;
  typology: string | null;
  client: string | null;
  location: string | null;
  link: string;
  levels: string | null;
  mImageUrl1: string;
  mImageUrl2: string;
  dImageUrl1: string;
  dImageUrl2: string;
  iHeading: string[] | null;
  iSubHeading1: string[] | null;
  iSubHeading2: string | null;
  impossibleText: string | null;
  iImageUrl: string;
  aSubHeading1: string | null;
  aSubHeading2: string | null;
  achievedText: string[] | null;
  aWatermarkUrl: string;
  aImageUrl: string;
}

// Define the array of AI projects
export const aiModel: AIProject[] = [
  {
    name: "Air Thane",
    place: "Thane",
    city: "Mumbai",
    cImageUrl: "/Assets/Projects/AIProjects/airThaneCarousel.png",
    country: "India",
    typology: "Mixed Use",
    client: "Q Group",
    location: "Thane West",
    link: "/air-thane",
    levels: "Ground + 5 Podiums + 42 Floors",
    mImageUrl1: "/Assets/Projects/AIProjects/airThane/thaneMapDesktop.jpg",
    mImageUrl2: "/Assets/Projects/AIProjects/airThane/thaneMapMobile.jpg",
    dImageUrl1: "/Assets/Projects/AIProjects/airThane/detailsPage1.jpg",
    dImageUrl2: "/Assets/Projects/AIProjects/airThane/detailsPage1.jpg",
    iHeading: ["A Healthy", " Lifestyle"],
    iSubHeading1: [
      "Economic",
      "Designer",
      "yet",
      "Iconic",
      "Construction friendly",
    ],
    iSubHeading2: "Being the New Mantra Post the Covid Pandemic",
    impossibleText: "...",
    iImageUrl: "/Assets/Projects/AIProjects/airThane/impossibleImage.png",
    aSubHeading1:
      "An innocent child playing with his paper windmill or a pinwheel or paper fan as kids call them",
    aSubHeading2:
      "this visual was the inspiration for the sketch that culminated to what is now called AIR Thane",
    achievedText: [
      "It imbibed all that was AMEER Architects intention…. Loved by kids….fresh….airy….healthy…active and an apt representation of the unseen element which is a necessity for all mankind….",
      "You see a child’s paper windmill and you see AIR….You see AIR Thane and you see the only development which truly represents a Healthy and Vibrant project…. ",
      "AA is a disciplined Architectural Firm which strongly follows the pursuit of “Form follows Function” Whilst doing so we ensure that the form so developed remains bespoke while providing the required Apartment Layouts….this is one of the constant achievements of AA across all their projects. Most of the construction within Thane, facilitate, Mivan formwork and look similar to some other high risers in the suburbs of Mumbai.",
      "AIR Thane, although Mivan has painstakingly gone into the intricacies of execution to look different and iconic at the same time. We at AA study wind tunnel impacts on all our high-risers irrespective of Regulatory mandates. We believe this creates efficiency in design, latent cost benefits and a better experience in general. In AIR we have used wind downdrafts in design to ventilate the landscaped areas. AIR boasts to be a symmetrical development within an asymmetrical plot.",
      "We’ve seen this on multiple occasions wherein Builders/Developers want Different…..but settle for a compromise….. Different, isn’t a colour tone….some finishes…some elevation treatment….it’s an approach incorporated within the basic design itself. Hence, we leave you with AIR Thane and decide yourself if you have seen such an iconic structure before….. ",
    ],
    aImageUrl: "/Assets/Projects/AIProjects/airThane/impossibleImage.png",
    aWatermarkUrl: "/Assets/Projects/AIProjects/airThane/aWatermark.png",
  },
  {
    name: "Eiffel Tower",
    place: "Mazgaon",
    city: "Mumbai",
    country: "India",
    cImageUrl: "/Assets/Projects/AIProjects/eiffelTowerCarousel.jpg",
    typology: "Mixed Use",
    client: "Empress Developers",
    location: null,
    link: "/eiffel-tower",
    levels: "Ground + 8 Podiums + 39 floors + 47 Levels",
    mImageUrl1: "/Assets/Projects/AIProjects/eiffelTower/eiffelMapDesktop.jpg",
    mImageUrl2: "/Assets/Projects/AIProjects/eiffelTower/eiffelMapMobile.jpg",
    dImageUrl1: "/Assets/Projects/AIProjects/eiffelTower/detailsPage1.jpg",
    dImageUrl2: "/Assets/Projects/AIProjects/eiffelTower/detailsPage2.jpg",
    iHeading: ["Wind", "Resistant"],
    iSubHeading1: ["Standing", "& yet", "Slender", "Tall", "Strong"],
    iSubHeading2: "High Rise Tower battling extreme wind pressure",
    impossibleText:
      "A Complex Project requiring a large quantum of FSI consumption viz-a-viz it’s available plot footprint. While, this holds true for most high rise projects, when confronted with Eiffel the Wind Pressure played an important part by almost cancelling the top 7 Floors of the project in terms of Permissible Structural Deflection & Acceleration mainly due to the prevalent South West winds in that location. Ameer Architects was handed over the herculean task of making the Eiffel Tower Stand Tall, Consume it’s entire FSI, Keep the project Economical at all times, Have an Uber Luxurious feeling especially within it’s topmost premium apartments (the ones taking the Maximum South West wind pressure onslaught) and after all that ensure it remain compliant with the High Rise norms especially with respect to the permissible deflection caused by the prevalent wind pressure.",
    iImageUrl: "/Assets/Projects/AIProjects/eiffelTower/impossibleEiffel.jpg",
    aSubHeading1:
      "A Deflection of 311 mm, while the permissible deflection was 324 mm",
    aSubHeading2:
      "this was achieved without compromising of the functionality of these Luxurious Apartments",
    achievedText: [
      "Ameer Architects had to multitask on Eiffel for 3 complete months, wherein the Architectural Design and the Structural Detailing went hand-in-hand. We had to provide some MEP flexibility at the same time for such luxurious residences wherein buyer would want to realign their own plumbing, sanitary as and when desired. This was easier said than done. All 3 Architectural, Structural and MEP verticals were being managed inhouse to better the resultant efficiency in the final outcome.",
      "We knew that high rise towers have a summation effect on wind pressure so while you may handle the top floor levels, the base might react differently thereafter and vice-a-versa.",
      "Therefore Ameer Architects had to run the required test and revisit Structural reinforcement and plumbing sunks, sleeves every time we added a floor. We finally closed the project with a deflection of 311 mm, while the permissible deflection was 324 mm. Like every other achievement of ours the actual skill set lies in making the final outcome look seamless at all times.",
    ],
    aImageUrl: "/Assets/Projects/AIProjects/eiffelTower/impossibleEiffel.jpg",
    aWatermarkUrl:"/Assets/Projects/AIProjects/eiffelTower/aWatermark.png",
  },
  {
    name: "Dharavi",
    place: "Dharavi",
    city: "Mumbai",
    country: "India",
    cImageUrl: "/Assets/Projects/AIProjects/dharaviCarousel.png",
    typology: "Mixed Use",
    client: "People of Dharavi",
    location: null,
    link: "/dharavi",
    levels: null,
    mImageUrl1: "/Assets/Projects/AIProjects/dharavi/dharaviMapDesktop.jpg",
    mImageUrl2: "/Assets/Projects/AIProjects/dharavi/dharaviMapMobile.jpg",
    dImageUrl1: "/Assets/Projects/AIProjects/dharavi/detailsPage1.png",
    dImageUrl2: "/Assets/Projects/AIProjects/dharavi/detailsPage2.png",
    iHeading: ["People", "Centric"],
    iSubHeading1: ["By the People", "of", "Dharavi", "For the People"],
    iSubHeading2: "Making Dharavi Unexploitable",
    impossibleText:
      "One of the most challenging Redevelopment Projects, the World’s Largest Slum was taken up by Ameer Architects to provide the people of Dharavi an unexploited design suiting the Macro as well as Micro requirements of the residents & it’s derived Sale Component.",
    iImageUrl: "/Assets/Projects/AIProjects/dharavi/impossibleDharavi.png",
    aSubHeading1:
      "Proud to be the People’s Architect for the Dharavi Redevelopment Project (DRP)",
    aSubHeading2:
      "Ameer Architects is the ONLY firm which has created a WORKABLE solution for DRP",
    achievedText: [
      "There have been numerous master plans for Dharavi, but no architect has yet detailed a solution which has looked into every existing obstacle of the DRP.",
      "Some of the impossible achieved by AA are as enlisted below:",
      "Rehab and Sale, both Designed with Structural Columns within the overlapped and existing Plots & DP Remarks.",
      "Relocating tenants through meetings and discussion towards various zones of the Rehab portion.",
      "Respecting and maintaining Prime Religious structures",
      "Parking Zones",
      "Commercial Outlets and aligning them appropriately through discussion and their projected business formats.",
      "Defining a Sale which would not be hampered by it’s vicinity and consume the available FSI",
      "For more information, you may visit https://m.facebook.com/Dharavision-118915213299222/?ref=bookmarks or call 8484929377",
      "Public Announcements under Dharavision;",
      "SIGNED Letter of Commitment & Consent forms for Residents to Join in.",
      "https://www.facebook.com/permalink.php?story_fbid=125708979286512&id=118915213299222&__tn__=-R",
      "The Layout of a Rehabilitated Slum Apartment under DHARAVISION.",
      "https://www.facebook.com/118915213299222/photos/a.132369761953767/132411848616225/?type=3&theater",
      "Why is Dharavi Not getting Redeveloped ? - Public Statement DHARAVISION",
      "https://www.facebook.com/118915213299222/photos/a.132369761953767/132369458620464/?type=3&theater",
      "What is DHARAVISION ?",
      "https://www.facebook.com/118915213299222/photos/a.132369761953767/132370231953720/?type=3&theater",
    ],
    aImageUrl: "/Assets/Projects/AIProjects/dharavi/impossibleDharavi.png",
    aWatermarkUrl: "/Assets/Projects/AIProjects/dharavi/aWatermark.png",

  },
  {
    name: "The Albatross",
    place: "Chembur",
    city: "Mumbai",
    country: "India",
    cImageUrl: "/Assets/Projects/AIProjects/albatrossCarousel.png",
    typology: "Residential",
    client: "Safal Group",
    location: null,
    link: "/the-albatross",
    levels: "Basement + Stilt + Podium + 15 floors",
    mImageUrl1: "/Assets/Projects/AIProjects/theAlbatross/albatrossMapDesktop.jpg",
    mImageUrl2: "/Assets/Projects/AIProjects/theAlbatross/albatrossMapMobile.jpg",
    dImageUrl1: "/Assets/Projects/AIProjects/theAlbatross/detailsPage1.png",
    dImageUrl2: "/Assets/Projects/AIProjects/theAlbatross/detailsPage2.png",
    iHeading: ["200+", "Expansive Apartments"],
    iSubHeading1: ["Restricted Plot", "incorporating"],
    iSubHeading2: "Vastu compliant with Unrestricted Views",
    impossibleText:
      "A Redevelopment project, Borla Society sharing it’s compound wall with the Chembur Golf Club. In excess of 200 society members, handling redevelopment projects has always been an USP of Ameer Architects. Besides the basic obstacles like varying member requirements, vastu compliance etc., the actual site shape of Borla along with its height restrictions provided a very prominent obstacle to overcome. The residents of Borla are habitual to live in an expansive manner and wanted a proposal to not isolate them into a claustrophobic zone. Parallel the Sale for a Golf Course viewing Residential block would demand a similar feel if not more. All this had to be designed within an odd shaped plot which as it is was being impacted by amenity and road widening deletions.",
    iImageUrl:
      "/Assets/Projects/AIProjects/theAlbatross/impossibleAlbatross.png",
    aSubHeading1: "An Open Web Structure",
    aSubHeading2:
      "Was Achieved to provide most residents with expansive views of the Gold Course",
    achievedText: [
      "Ameer Architects had to create a Residential Design for the Sale & Rehab portion, wherein the Rehab was intertwined in a unique manner and yet have the Construction to Carpet efficiency to keep the project economical at all times.",
      "The building wings so designed were in the form of an Open Web. The Structure was aligned across all four corners / directions of the project so that there was only a play/extendable length of 0.5 meter in any given direction. This again would spread with Structural incorporations and the project was realigned in accordance until the entire Rehab was designed in a format that it felt as though the plot was made for the building rather then the other way around.",
      "We had to thereafter manage the Parking Zones within this envelope and also the other peripheral Services, RG Area etc. An interesting project, the Golf Club Residences were finally provided with an Uber Luxury set of apartments within it’s Sale Portion.",
    ],
    aImageUrl: "/Assets/Projects/AIProjects/theAlbatross/achievedAlbatross.png",
    aWatermarkUrl: "/Assets/Projects/AIProjects/theAlbatross/aWatermark.png",

  },
];
