import React from 'react'
import { useLocation } from 'react-router-dom';
import { CompletedProjectsInterface } from './Model/CompletedModel';
import NavBarComponent from '../../NavBarComponent';
import { Col, Container, Row } from 'react-bootstrap';
import "../../../styles/onGoingProjectDetails.scss";


const CompletedProjectDetails = () => {
    const location = useLocation();
    const completedProjectData = location.state as CompletedProjectsInterface;
    // console.log(completedProjectData);
    return (
        <div className="onGoingDetails">
            <NavBarComponent backgroundColor="black" />
            <Container fluid className="onGoingDetailsContainer">
                <div className="onGoingDetailsDiv">
                    <Row className="onGoingfirstRow">
                        <Col lg={7} className="imageSection">
                            <img
                                src={process.env.PUBLIC_URL + completedProjectData.dImageUrl1}
                                alt="image"
                            />
                        </Col>
                        <Col lg={5} className="detailContainer">
                            <div className="firstSection">
                                <p className="spanRed">Details:</p>
                                <p>
                                    Typology:{" "}
                                    <span className="detailsValue">
                                        {completedProjectData.typology ?? "NA"}
                                    </span>
                                </p>
                                <p>
                                    Client:{" "}
                                    <span className="detailsValue">
                                        {" "}
                                        {completedProjectData.client ?? "NA"}
                                    </span>
                                </p>
                                <p>
                                    Location:{" "}
                                    <span className="detailsValue">
                                        {" "}
                                        {completedProjectData.location ?? "NA"}
                                    </span>
                                </p>
                                <p>
                                    Construction Area:
                                    <span className="detailsValue">
                                        {" "}
                                        {completedProjectData.constructionArea ?? "NA"}
                                    </span>
                                </p>
                                <p>
                                    Levels:{" "}
                                    <span className="detailsValue">
                                        {" "}
                                        {completedProjectData.levels ?? "NA"}
                                    </span>
                                </p>
                            </div>
                            <div className="secondSection">
                                <p className="spanRed">Description:</p>
                                <p>{completedProjectData.desc}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="onGoingSecondRow">
                        <Col className="onGoingProjectName">{completedProjectData.name}</Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default CompletedProjectDetails