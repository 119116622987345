import React from 'react'
import { Container } from 'react-bootstrap'
import ProjectsContentComponent from '../components/Projects/ProjectsContentComponent'
import { motion } from 'framer-motion'
import NavBarComponent from '../components/NavBarComponent'

const Projects = () => {
    return (
        <motion.div className='black-Background' initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <NavBarComponent />
            <Container fluid>
                <ProjectsContentComponent />
            </Container>

        </motion.div>
    )
}

export default Projects