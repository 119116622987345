import { Tab, Nav } from "react-bootstrap";
import "../../styles/projectMap.scss";
import NavBarComponent from "../NavBarComponent";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Details from "./Tabs/Details";
import { AIProject } from "./Tabs/Model/AIModel";
import Impossible from "./Tabs/Impossible";

const ProjectMap = () => {
  const [projectMap, setProjectMap] = useState("projectMapImage font");
  const [navBarBackground, setNavBarBackground] = useState("");
  const location = useLocation();
  const propsData = location.state as AIProject;
  const handleNavClick = () => {
    setProjectMap("projectMapBlack font");
    setNavBarBackground("black");
  };
  return (
    <div className={projectMap}>
      <NavBarComponent backgroundColor={navBarBackground} />
      <div className="mapLarge d-none d-lg-block">
        <Tab.Container id="left-tabs-example">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <Details aiProjectData={propsData} />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <Impossible aiProjectData={propsData} />
            </Tab.Pane>
            <Tab.Pane eventKey="third">Second tab content</Tab.Pane>
          </Tab.Content>
          <Nav className="bottomNav" variant="pills">
            <div>
              <h1>{propsData?.name}</h1>
              <div className="nav-items">
                <Nav.Item>
                  <Nav.Link eventKey="first" onClick={handleNavClick}>
                    Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" onClick={handleNavClick}>
                    Impossible
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third" onClick={handleNavClick}>
                    Achieved
                  </Nav.Link>
                </Nav.Item>
              </div>
            </div>
          </Nav>
        </Tab.Container>
      </div>
      <div className="mapMedium d-lg-none">
      </div>
    </div>
  );
};

export default ProjectMap;
