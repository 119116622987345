import React from "react";
import NavBarComponent from "../../NavBarComponent";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { OnGoingProjectsInterface } from "./Model/OnGoingModel";
import "../../../styles/global.scss";
import "../../../styles/onGoingProjectDetails.scss";

const OnGoingProjectDetails = () => {
    const location = useLocation();
    const onGoingProjectData = location.state as OnGoingProjectsInterface;
    // console.log(onGoingProjectData);
    return (
        <div className="onGoingDetails">
            <NavBarComponent backgroundColor="black" />
            <Container fluid className="onGoingDetailsContainer">
                <div className="onGoingDetailsDiv">
                    <Row className="onGoingfirstRow">
                        <Col lg={7} className="imageSection">
                            <img
                                src={process.env.PUBLIC_URL + onGoingProjectData.dImageUrl1}
                                alt="image"
                            />
                        </Col>
                        <Col lg={5} className="detailContainer">
                            <div className="firstSection">
                                <p className="spanRed">Details:</p>
                                <p>
                                    Typology:{" "}
                                    <span className="detailsValue">
                                        {onGoingProjectData.typology ?? "NA"}
                                    </span>
                                </p>
                                <p>
                                    Client:{" "}
                                    <span className="detailsValue">
                                        {" "}
                                        {onGoingProjectData.client ?? "NA"}
                                    </span>
                                </p>
                                <p>
                                    Location:{" "}
                                    <span className="detailsValue">
                                        {" "}
                                        {onGoingProjectData.location ?? "NA"}
                                    </span>
                                </p>
                                <p>
                                    Construction Area:
                                    <span className="detailsValue">
                                        {" "}
                                        {onGoingProjectData.constructionArea ?? "NA"}
                                    </span>
                                </p>
                                <p>
                                    Levels:{" "}
                                    <span className="detailsValue">
                                        {" "}
                                        {onGoingProjectData.levels ?? "NA"}
                                    </span>
                                </p>
                            </div>
                            <div className="secondSection">
                                <p className="spanRed">Description:</p>
                                <p>{onGoingProjectData.desc}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="onGoingSecondRow">
                        <Col className="onGoingProjectName">{onGoingProjectData.name}</Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default OnGoingProjectDetails;
