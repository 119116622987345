import React, { useEffect, useState } from "react";
import { Tab, Nav, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { AIProject } from "../components/Projects/Tabs/Model/AIModel";
import NavBarComponent from "../components/NavBarComponent";
import Details from "../components/Projects/Tabs/Details";
import Achieved from "../components/Projects/Tabs/Achieved";

export const EiffelTower = () => {
  const [projectMap, setProjectMap] = useState("projectMapImage font");
  const [navItemColor, setNavItemColor] = useState("white");
  const [navItemShadow, setNavItemShadow] = useState("2px 2px 4px black");
  const [navClass, setNavClass] = useState("desktopbottomNav");
  const [tabContent, setTabContent] = useState("desktopTabContents");
  const [tabContentBackground, settabContentBackground] = useState("black");
  const [hideTab, setTab] = useState("none");
  const [navBarBackground, setNavBarBackground] = useState("");
  const location = useLocation();
  const propsData = location.state as AIProject;
  const [backgroundImage, setbackgroundImage] = useState(
    process.env.PUBLIC_URL + propsData?.mImageUrl1
  );
  const handleNavClick = (tab: string) => {
    if (tab === "third") {
      setNavBarBackground("white");
      setProjectMap("projectMapWhite font");
      setNavItemColor("black");
      settabContentBackground("white");
    } else {
      setProjectMap("projectMapBlack font");
      setNavBarBackground("black");
      setNavItemColor("white");
      settabContentBackground("black");
    }
    setNavItemShadow("none");
    setTab("block");
    setbackgroundImage("none");
    setNavClass("mobilebottomNav");
    setTabContent("mobileTabContents");
  };
  useEffect(() => {
    function handleResize() {
      // Check if the window width is smaller than 991px
      if (window.innerWidth < 991) {
        setbackgroundImage(process.env.PUBLIC_URL + propsData?.mImageUrl2);
      } else {
        setbackgroundImage(process.env.PUBLIC_URL + propsData?.mImageUrl1);
      }
    }

    // Call handleResize initially to set the initial background image
    handleResize();

    // Add event listener to handle resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [propsData]);
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: navBarBackground,
      }}
      className="largeScreen"
    >
      <NavBarComponent backgroundColor={navBarBackground} />
      <Tab.Container id="left-tabs-example">
        <div
          style={{ backgroundColor: tabContentBackground }}
          className={tabContent}
        >
          <Tab.Content style={{ display: hideTab }}>
            <Tab.Pane eventKey="first">
              <Details aiProjectData={propsData} />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="projectImpossible">
                <Row>
                  <Col md={12} lg={6} className="imageSec">
                    <div className="d-block d-lg-none">
                      <h1>
                        <i>The</i> <span className="color">Im</span>possible{" "}
                        <i>Task</i>
                      </h1>
                      <div className="headQuote">
                        <div className="line1">
                          Standing <span className="color">Slender</span>
                        </div>
                        <div className="line2">
                          Standing <span className="color">Tall</span>
                        </div>
                        <div className="line3">
                          <span>& yet</span>
                        </div>
                        <div className="line4">
                          <span>
                            Standing <span className="color"> Strong</span>
                          </span>
                        </div>
                      </div>
                      <span className="quoteHeading">
                        <span className="headingForImage">
                          {propsData.iHeading?.[0]}
                        </span>
                        <span className="iHeading">
                          {propsData.iHeading?.[1]}
                        </span>
                      </span>
                      <p style={{ textAlign: "center" }}>
                        "{propsData.iSubHeading2}"
                      </p>
                    </div>
                    <img
                      src={process.env.PUBLIC_URL + propsData.iImageUrl}
                      alt="Image"
                      title="image"
                    />
                  </Col>
                  <Col md={12} lg={6} className="detailsSection">
                    <div className="d-none d-lg-block">
                      <h1>
                        <i>The</i> <span>Im</span>possible <i>Task</i>
                      </h1>
                      <div className="headQuote">
                        Standing <span className="color">Slender</span> ,
                        Standing <span className="color">Tall</span>
                        <span
                          style={{
                            display: "block",
                            marginLeft: "10%",
                          }}
                        >
                          & yet
                        </span>
                        <span
                          style={{
                            display: "block",
                            marginLeft: "5%",
                          }}
                        >
                          Standing <span className="color"> Strong</span>
                        </span>
                      </div>
                      <span className="quoteHeading">
                        <span className="headingForImage">
                          {propsData.iHeading?.[0]}
                        </span>
                        <span className="iHeading">
                          {propsData.iHeading?.[1]}
                        </span>
                      </span>
                      <p style={{ textAlign: "center" }}>
                        "{propsData.iSubHeading2}"
                      </p>
                    </div>

                    <p>
                      A Complex Project requiring a large quantum of FSI
                      consumption viz-a-viz it’s available plot footprint.
                      While, this holds true for most high rise projects, when
                      confronted with Eiffel the Wind Pressure played an
                      important part by almost cancelling the top 7 Floors of
                      the project in terms of Permissible Structural Deflection
                      & Acceleration mainly due to the prevalent South West
                      winds in that location.
                    </p>
                    <p>
                      Ameer Architects was handed over the herculean task of
                      making the Eiffel Tower Stand Tall, Consume it’s entire
                      FSI, Keep the project Economical at all times, Have an
                      Uber Luxurious feeling especially within it’s topmost
                      premium apartments (the ones taking the Maximum South West
                      wind pressure onslaught) and after all that ensure it
                      remain compliant with the High Rise norms especially with
                      respect to the permissible deflection caused by the
                      prevalent wind pressure.
                    </p>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <Achieved aiProjectData={propsData} />
            </Tab.Pane>
          </Tab.Content>
          <Nav className={navClass} variant="pills">
            <div>
              <h1 style={{ color: navItemColor, textShadow: navItemShadow }}>
                {propsData.name}
              </h1>
              <div className="nav-items">
                <Nav.Item>
                  <Nav.Link
                    style={{ color: navItemColor, textShadow: navItemShadow }}
                    eventKey="first"
                    onClick={() => handleNavClick("first")}
                  >
                    Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ color: navItemColor, textShadow: navItemShadow }}
                    eventKey="second"
                    onClick={() => handleNavClick("second")}
                  >
                    Impossible
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ color: navItemColor, textShadow: navItemShadow }}
                    eventKey="third"
                    onClick={() => handleNavClick("third")}
                  >
                    Achieved
                  </Nav.Link>
                </Nav.Item>
              </div>
            </div>
          </Nav>
        </div>
      </Tab.Container>
    </div>
  );
};
