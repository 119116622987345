import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AIProject } from "./Model/AIModel";
import "../../../styles/achieved.scss";

const Achieved: React.FC<{ aiProjectData: AIProject }> = ({
  aiProjectData,
}) => {
  const [backgroundWatermark] = useState(`url(${aiProjectData.aWatermarkUrl})`);
  const renderParagraphs = () => {
    return aiProjectData?.achievedText?.map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
  };
  return (
    <div className="projectAchieved">
      <Row>
        <Col
          md={12}
          lg={6}
          style={{ backgroundImage: backgroundWatermark }}
          className="detailSection"
        >
          <div className="backImage">
            <div className="d-none d-lg-block">
              <h1>
                <i>The Task</i> <span className="color">A</span>chieved
              </h1>
              <div className="aSubHeading1">
                "{aiProjectData?.aSubHeading1}"
              </div>
              <div className="aSubHeading2">{aiProjectData?.aSubHeading2}</div>
              <br />
            </div>
            <div className="achievedText">{renderParagraphs()}</div>
          </div>
        </Col>
        <Col md={12} lg={6} className="imageSection">
          <div className="d-block d-lg-none">
            <h1>The Task Achieved</h1>
            <div className="aSubHeading1">"{aiProjectData?.aSubHeading1}"</div>
            <div className="aSubHeading2">{aiProjectData?.aSubHeading2}</div>
          </div>
          <br />
          <img src={aiProjectData?.aImageUrl} alt="image" />
        </Col>
      </Row>
    </div>
  );
};

export default Achieved;
