import { Carousel, Row, Col, Card } from "react-bootstrap"
import { onGoingModel } from "./Model/OnGoingModel";
import { Link } from "react-router-dom";
import "../../../styles/onGoing.scss"

const OngoingComponent = () => {
    return (
        <div>
            <Carousel className="projectCarousel d-none d-lg-block" indicators={false} interval={null}>
                {Array.from({ length: Math.ceil(onGoingModel.length / 4) }).map((_, index) => (
                    <Carousel.Item key={index}>
                        <Row>
                            {onGoingModel.slice(index * 4, index * 4 + 4).map((project, cardIndex) => (
                                <Col key={cardIndex}>
                                    <Link to={"/ongoing-project-details"} state={project}>
                                        <Card className={cardIndex % 2 === 0 ? "even bg-transparent" : "odd bg-transparent"}>
                                            <Card.Img variant="top" src={process.env.PUBLIC_URL + project.cImageUrl} alt="Card image" className="cardImageOnGoing" />
                                            <Card.Body>
                                                <Card.Title>{project.name}</Card.Title>
                                                <Card.Text>{project.city}, {project.country}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </Carousel.Item>
                ))}
            </Carousel>
            <div className="mobileCard d-lg-none">
                {onGoingModel.map((data, index) => (
                    <Card className="smallCard" key={index}>
                        <Card.Img variant="top" src={process.env.PUBLIC_URL + data.cImageUrl} alt="Card image" />
                        <Card.Body>
                            <Row className="mobileRow">
                                <Col xs={8} md={10}>
                                    <Card.Title>{data.name}</Card.Title>
                                    <Card.Text>{data.city}, {data.country}</Card.Text>
                                </Col>
                                <Link to={"/ongoing-project-details"} state={data}>
                                    <Col className="d-flex justify-content-center align-items-center" xs={4} md={2}>
                                        <p>Explore &gt;</p>
                                    </Col>
                                </Link>
                            </Row>
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </div>
    )
}

export default OngoingComponent