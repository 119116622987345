import React from 'react'
import NavBarComponent from '../components/NavBarComponent'
import AIContentComponent from '../components/AI/AIContentComponent'
import { Container } from 'react-bootstrap'
import "../styles/global.scss"
import { motion } from 'framer-motion'


const AI = () => {
    return (
        <motion.div className='black-Background' initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <NavBarComponent />
            <Container fluid>
                <AIContentComponent />
            </Container>
        </motion.div>
    )
}

export default AI