import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import '../../styles/projectContent.scss';
import AIProjectsComponent from './Tabs/AIProjects';
import OngoingComponent from './Tabs/Ongoing';
import CompletedComponent from './Tabs/Completed';

const ProjectsContentComponent = () => {
  return (
    <div className='projectContent'>
      <Row>
        <Col className='projectHeading'>
          <h3>Projects Associated with</h3> 
        </Col>
      </Row>
      <Row>
        <Col className='projectTabs'>
          <Tabs
            defaultActiveKey="AI"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="AI" title={
              <h5>
                <span>AI </span>
                Projects
              </h5>
            }>
              <AIProjectsComponent></AIProjectsComponent>
            </Tab>
            <Tab eventKey="ongoing" title={
              <h5>Ongoing</h5>
            }>
              <OngoingComponent></OngoingComponent>
            </Tab>
            <Tab eventKey="compoleted" title={
              <h5>
                Completed
              </h5>
            }>
              <CompletedComponent></CompletedComponent>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  )
}

export default ProjectsContentComponent