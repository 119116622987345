import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AI from "../pages/AI";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import EffortsGlobal from "../pages/EffortsGlobal";
import HomePage from "../pages/HomePage";
import MIAHouseOfDesign from "../pages/MIAHouseOfDesign";
import Projects from "../pages/Projects";
import ProjectMap from "../components/Projects/ProjectMap";
import { AnimatePresence } from "framer-motion";
import MapMobileView from "./Projects/Tabs/MapMobileView";
import OnGoingProjectDetails from "./Projects/Tabs/OnGoingProjectDetails";
import { AirThane } from "../pages/AirThane";
import { EiffelTower } from "../pages/EiffelTower";
import { Dharavi } from "../pages/Dharavi";
import { TheAlbatross } from "../pages/TheAlbatross";
import CompletedProjectDetails from "./Projects/Tabs/CompletedProjectDetails";
import OtherProjects from "../pages/OtherProjects";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/ai" element={<AI />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/mia-houseOfDesign" element={<MIAHouseOfDesign />} />
        <Route path="/efforts-global" element={<EffortsGlobal />} />
        <Route path="/project-detail" element={<ProjectMap />} />
        <Route path="/project-detail-mobile" element={<MapMobileView />} />
        <Route
          path="/ongoing-project-details"
          element={<OnGoingProjectDetails />}
        />
        <Route path="/air-thane" element={<AirThane />} />
        <Route path="/eiffel-tower" element={<EiffelTower />} />
        <Route path="/dharavi" element={<Dharavi />} />
        <Route path="/the-albatross" element={<TheAlbatross />} />
        <Route
          path="/ongoing-project-details"
          element={<OnGoingProjectDetails />}
        />
        <Route
          path="/completed-project-details"
          element={<CompletedProjectDetails />}
        />
        <Route path="/other-projects" element={<OtherProjects />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
