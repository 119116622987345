import React from "react";
import NavBarComponent from "../components/NavBarComponent";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import {
  OtherProjectsInterface,
  OtherProjectsModel,
} from "../components/Projects/Tabs/Model/OtherProjectsModel";
import "../styles/otherProjects.scss";
import "../styles/global.scss";

const OtherProjects = () => {
  // Function to group projects into pairs
  const groupProjectsIntoPairs = (projects: OtherProjectsInterface[]) => {
    const pairs = [];
    for (let i = 0; i < projects.length; i += 2) {
      const pair = projects.slice(i, i + 2);
      pairs.push(pair);
    }
    return pairs;
  };

  const pairsOfProjects = groupProjectsIntoPairs(OtherProjectsModel);

  return (
    <div className="black-Background">
      <NavBarComponent backgroundColor="black" />
      <div className="otherProjectMaindiv">
        <div className="otherProject">
          <Container fluid className="otherProjectsContainerClass">
            <Row className="content">
              {OtherProjectsModel.map((project, index) => (
                // <Row className="testing custom-img" key={index}>
                // <Row sm={12} md={12} lg={3} className="otherProjectsImg" key={index}>
                //   <img
                //     className={
                //       index % 2 === 0
                //         ? "even d-block w-100"
                //         : "odd d-block w-100"
                //     }
                //     src={project.dImageUrl1}
                //     alt={project.name}
                //   />
                //   <h3 className="fontWhite">{project.name}</h3>
                // </Row>
                <Col key={index} lg={3} md={6} sm={12} xs={12} className="otherProjectsImg">
                  <img src={process.env.PUBLIC_URL + project.dImageUrl1} alt={`Image ${index + 1}`} className="img-fluid" />
                  <h3 className="fontWhite">{project.name}</h3>
                </Col>
                // </Row>
              ))}
            </Row>
          </Container>
        </div>
        <div className="otherProjectTag">
          <Col className="fontWhite otherProjectsDisplay">Other Projects</Col>
        </div>
      </div>

    </div>
  );
};

export default OtherProjects;
