export interface CompletedProjectsInterface {
    name: string;
    place: string;
    city: string;
    cImageUrl: string;
    dImageUrl1: string;
    country: string;
    typology: string;
    client: string;
    levels: string;
    desc: string[] | null;
    constructionArea: string | null;
    location: string;
    link: string | null;
  }
export const completedModel : CompletedProjectsInterface[] = [
    {
        name: 'Dubailand Snowdome',
        place : "",
        city: 'Dubai,',
        cImageUrl: '/Assets/Projects/Completed/dubaiDomeCarousel.jpg',
        dImageUrl1 : "/Assets/Projects/Completed/DubaiLandSnowdome/SkyDome.jpg",
        country: 'United Arab Emirates',
        typology: 'Amusement Park',
        client: '32Group',
        levels: '75 meters',
        desc: [
            'The dome is designed to be the largest freestanding transparent structure in the world reaching a diameter of 200+ meters and a height of 70+ meters.',
            'A cable lift taking you at the top of the highest mountain, a rotating ski deck with training area, a mountain run, most exciting snow play area, toboggan run, ice skating rink, ice skating adventure area, ice rink sports area, a flying theatre featuring a virtual flight... Other peripherals included within are a 4star Hotel and Iceberg Residences.'
        ],
        constructionArea : "",
        location : "",
        link: "",
    },
    {
        name: 'Waterfront Hotel',
        place : "",
        city: 'Dubai,',
        cImageUrl: '/Assets/Projects/Completed/waterfrontCarousel.jpg',
        // dImageUrl1 : "/Assets/Projects/Completed/WaterFrontHotel/WaterFrontHotel.jpg",
        dImageUrl1 : "/Assets/Projects/Completed/WaterFrontHotel/WaterFrontHotel-transformed.jpeg",
        country: 'United Arab Emirates',
        typology: 'Hospitality & Commercial',
        client: 'Mr. Vitelli (VIP Waterfront Real Estate)',
        levels: '46 Floors',
        desc: [
            'This is an iconic project of unreal proportions. The project brief itself was a challenge wherein we needed to Design Hotels and Commercial Towers on 2 similar plots, erected on top of a Shopping Mall which spanned further ahead of the hotel floor plate. I then had to provide Villa’s and Chalets on top of the mall and in front of the Hotel Towers. Finally there is a Palace devised in front of the facade and the entire premise had a waterfront view hence the name of the project. It was challenging as a result of the various functions allotted to a single site and furthermore it had just one side of the plot for all the accesses and yet were to be exclusively defined as per their zones. We had completed its schematic Mechanical and Structural design post a preliminary design approval from JAFZA.'
        ],
        constructionArea : "",
        location : "",
        link: "",
    },
    {
        name: 'The Burj Dubai Lake Hotel',
        place : "",
        city: 'Dubai,',
        cImageUrl: '/Assets/Projects/Completed/burjDubaiCarousel.png',
        // dImageUrl1 : "/Assets/Projects/Completed/BurjDubaiLakeHotel/BurjDubailakeHotel.png",
        dImageUrl1 : "/Assets/Projects/Completed/BurjDubaiLakeHotel/BurjDubailakeHotel-transformed.png",
        country: 'United Arab Emirates',
        typology: 'Residential + Hospitality',
        client: '',
        levels: '63 Floors',
        desc: [
            'The Burj Dubai Lake Hotel is a residential cum hospitality component of the AED 73 billion (US$20 billion) Downtown Burj Dubai, which encompasses the Burj Khalifa.',
            'Under the flagship of Atkins the entire project has been detailed to create a 306m super-tall skyscraper in the form of 63 storeys. Designed in form to compliment the Burj Khalifa, the crown of this structure was revised numerous times even through its construction. Multi-levelled basements were used to house a complex circulation of hospitality services.'
        ],
        constructionArea : "",
        location : "",
        link: "",
    },
    {
        name: 'Bahrain World Trade Centre',
        place : "",
        city: 'Manama,',
        cImageUrl: '/Assets/Projects/Completed/bahrainCarousel.png',
        dImageUrl1 : "/Assets/Projects/Completed/BahrainWTC/Bahrain.png",
        country: 'Bahrain',
        typology: 'Commercial',
        client: '',
        levels: '50 Floors',
        desc: [
            'Briefly associated with The Bahrain World Trade Center (also called Bahrain WTC or BWTC). It is a 240-metre-high (787 ft), 50-floor, twin tower complex located in Manama, Bahrain. It is the first skyscraper in the world to integrate wind turbines into its design.'
        ],
        constructionArea : "",
        location : "",
        link: "",
    },
    {
        name: 'North Gate Tower',
        place : "",
        city: 'Jebel Ali, Dubai,',
        cImageUrl: '/Assets/Projects/Completed/northGateCarousel.jpg',
        dImageUrl1 : "/Assets/Projects/Completed/NorthGateTower/NorthGateTower.png",
        country: 'Uniter Arab Emirates',
        typology: 'Commercial',
        client: 'Adil Dean',
        levels: '6 Basements+Ground+4 Podiums+26 Floors',
        desc: [
            'Located in Downtown Jebel Ali, this 26 storey structure is a Commercial Tower. Currently awaiting an Army NOC for its location being adjacent to UAE army zone, it has been Managed by me through its Schematic and Detailed design stages. For this project I have dealt with Ellisdon as a master planner and getting a preliminary approval from those authorities was currently dealing with JAFZA to get a building permit. For the same a Mechanical and Structural solution has been devised and approved by me and the client.'
        ],
        constructionArea : "",
        location : "",
        link: "",
    },
    {
        name: 'Beaconsfield Parade',
        place : "",
        city: 'Melbourne,',
        cImageUrl: '/Assets/Projects/Completed/beaconsfieldCarousel.jpg',
        // dImageUrl1 : "/Assets/Projects/Completed/BeaconsfieldParade/BeaconsfieldParade.jpg",
        dImageUrl1 : "/Assets/Projects/Completed/BeaconsfieldParade/BeaconsfieldParade-transformed.jpeg",
        country: 'Australia',
        typology: 'Residential',
        client: '',
        levels: 'Ground + 5 Floors',
        desc: [
            'Located at one of the most elite locations in Melbourne, Australia. This Residential complex had a strict height limitation along with well defined shading alignments as per the codes prevalent in Melbourne. Extensive collaboration was maintained with their approval boards to seek a final approval to commence execution. There was a requirement to develop high end residential floor plates. Challenge confronted, was to attain the same without compromising on the requisite heights for such residences.'
        ],
        constructionArea : "",
        location : "",
        link: "",
    },
    {
        name: 'ETA SEMS School',
        place : "",
        city: 'Mirdif, Dubai,',
        cImageUrl: '/Assets/Projects/Completed/ETACarousel.jpg',
        // dImageUrl1 : "/Assets/Projects/Completed/ETASEMSSchool/ETASchool.jpg",
        dImageUrl1 : "/Assets/Projects/Completed/ETASEMSSchool/ETASchool-transformed.jpeg",
        country: 'United Arab Emirates',
        typology: 'Private School',
        client: 'Mrs. Maryam Salahuddin (ETA SEMS)',
        levels: 'Ground + 4 Floors',
        desc: [
            'There were 2 significant issues to be handled in the development of this project. First, the general concept was to provide a schooling facility which would encompass around 2 focal structures of a multipurpose hall and an auditorium . Keeping in mind both these were sound generating zones, providing classrooms around its periphery needed us to study various materials and modes of construction to achieve this task',
            'Secondly, the plot had access from opposite sides and had a level difference of 3. 5 Meters which was to be adjusted within the plot via design parameters. I even had to manage the basic drainage in accordance with this level difference. I was the Project architect for the School and had managed it entirely with external Mechanical and Structural teams. We needed to complete the project prior to the school opening date which was fixed for the city and hence it was challenging to attain solutions, approvals, permits, floating tenders etc. within the stipulated time frame'
        ],
        constructionArea : "",
        location : "",
        link: "",
    },
    {
        name: 'Other Projects...',
        place : "",
        city: '',
        cImageUrl: '/Assets/blackImage.png',
        // dImageUrl1 : "/Assets/Projects/Completed/ETASEMSSchool/ETASchool.jpg",
        dImageUrl1 : "",
        country: '',
        typology: '',
        client: '',
        levels: '',
        desc: [],
        constructionArea : "",
        location : "",
        link: "/other-projects",
    }
]
