import React, { useState } from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import "../styles/navbar.scss";
interface NavBarComponentProps {
  backgroundColor?: string;
}

const NavBarComponent: React.FC<NavBarComponentProps> = ({
  backgroundColor,
}) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleHoverMouseLeave = () => {
    setIsHovered(false);
  };

  let navBarBlack =
    location.pathname === "/ai" || location.pathname === "/projects"
      ? "black"
      : "white";
  let logo = "";
  let navBarCssClassName = "";
  let navBarVaraint = "light";
  if (
    location.pathname === "/ai" ||
    location.pathname === "/projects" ||
    location.pathname === "/ongoing-project-details" ||
    location.pathname === "/project-detail-mobile" ||
    location.pathname === "/completed-project-details" ||
    location.pathname === "/other-projects"
  ) {
    logo = "/AA-LOGO-WHITE-LINE.png";
    navBarCssClassName = "navbarCssBlack";
    navBarBlack = "black";
    navBarVaraint = "dark";
  } else {
    logo = "AA-LOGO-FULL-RED.png";
    navBarCssClassName = "navbarCss";
    navBarBlack = "white";
  }

  if (
    location.pathname === "/air-thane" ||
    location.pathname === "/eiffel-tower" ||
    location.pathname === "/dharavi" ||
    location.pathname === "/the-albatross"
  ) {
    // navBarVaraint = "dark";
    if (backgroundColor && backgroundColor === "black") {
      navBarBlack = backgroundColor;
      navBarCssClassName = "navbarCssBlack";
    } else if (backgroundColor && backgroundColor === "white") {
      navBarBlack = backgroundColor;
      navBarCssClassName = "navbarCss";
    } else {
      navBarBlack = "";
      navBarCssClassName = "navbarCssBlack";
    }
  }

  return (
    <>
      <Navbar
        expand="lg"
        sticky="top"
        variant={navBarVaraint}
        className={navBarCssClassName}
        style={{ backgroundColor: `${navBarBlack}` }}
      >
        {/* <Container> */}
        <Navbar.Brand
          as={NavLink}
          to="/"
          onMouseEnter={handleHover}
          onMouseLeave={handleHoverMouseLeave}
          className="navBrand"
        >
          <div className="navBrandDiv">
            <img
              src={process.env.PUBLIC_URL + `${logo}`}
              className="img-logo"
              alt="Ameer Architects logo"
            />
            <div className="logoText">
              <h4 className="ameerText"><span className="capsA">A</span>MEER</h4>
              <h6 className="architectEngineerText">Architects | Engineers | Planners</h6>
            </div>
          </div>
          <img
            src={process.env.PUBLIC_URL + "/AA-LOGO-FULL-RED.png"}
            className="img-logo-hover"
            alt="Ameer Architects logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={NavLink} to="/" className="navLink">
              Home
            </Nav.Link>
            <Nav.Link as={NavLink} to="/about-us" className="navLink">
              About Us
            </Nav.Link>
            <Nav.Link as={NavLink} to="/ai" className="navLink AINavLink">
              AI
            </Nav.Link>
            <Nav.Link as={NavLink} to="/projects" className="navLink">
              Projects
            </Nav.Link>
            {/* <Nav.Link as={NavLink} to="/our-affiliates" className="navLink" >Our Affiliates</Nav.Link> */}
            <NavDropdown
              title="Our Affiliates"
              className="navLink"
              show={isOpen}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <NavDropdown.Item as={NavLink} to="/mia-houseOfDesign">
                MIA House of Design
              </NavDropdown.Item>
              {/* <NavDropdown.Divider /> */}
              <NavDropdown.Item as={NavLink} to="/efforts-global">
                Efforts Global
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={NavLink} to="/contact-us" className="navLink">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  );
};

export default NavBarComponent;
