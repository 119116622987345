export interface OtherProjectsInterface{
    name: string;
    dImageUrl1: string;
}

export const OtherProjectsModel : OtherProjectsInterface[] = [
    {
        name : "Safal Sai",
        dImageUrl1 : "/Assets/Projects/OtherProjects/SafalSai.jpeg",
    },
    {
        name : "Safal Sky",
        dImageUrl1 : "/Assets/Projects/OtherProjects/SafalSky.jpeg",
    },
    {
        name : "Fifth Season Manchandak Group",
        dImageUrl1 : "/Assets/Projects/OtherProjects/FifthSeasonGroup.jpeg",
    },
    {
        name : "Shivshakti (Collectors Colony)",
        dImageUrl1 : "/Assets/Projects/OtherProjects/ShivShakti.jpeg",
    },
]