import { Carousel, Row, Col, Card } from "react-bootstrap"
import { completedModel } from "./Model/CompletedModel"
import { Link } from "react-router-dom"
import "../../../styles/completed.scss"

const CompletedComponent = () => {
    // console.log(completedModel.length);
    return (
        <div>
            <Carousel className="projectCarousel d-none d-lg-block" indicators={false} interval={null}>
                {Array.from({ length: Math.ceil(completedModel.length / 4) }).map((_, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <Row>
                                {completedModel.slice(index * 4, index * 4 + 4).map((project, cardIndex) => (
                                    <Col key={cardIndex}>
                                        <Link to={project.link === "" ? "/completed-project-details" : "/other-projects"} state={project}>
                                            <Card
                                                className={cardIndex % 2 === 0 ? "even bg-transparent" : "odd bg-transparent"}
                                            >
                                                <Card.Img
                                                    variant="top"
                                                    src={process.env.PUBLIC_URL + project?.cImageUrl}
                                                    alt="Card image"
                                                />
                                                <Card.Body>
                                                    <Card.Title>{project?.name}</Card.Title>
                                                    <Card.Text>
                                                        {project?.city} {project?.country}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
            <div className="mobileCard d-lg-none">
                {completedModel.map((data, index) => (
                    <Card className="smallCard" key={index}>
                        <Card.Img variant="top" src={process.env.PUBLIC_URL + data.cImageUrl} alt="Card image" />
                        <Card.Body>
                            <Row className="mobileRow">
                                <Col xs={8} md={10}>
                                    <Card.Title>{data?.name}</Card.Title>
                                    <Card.Text>{data?.city} {data?.country}</Card.Text>
                                </Col>
                                <Link to={data.link === "" ? "/completed-project-details" : "/other-projects"} state={data}>
                                    <Col className="d-flex justify-content-center align-items-center" xs={4} md={2}>
                                        <p>Explore &gt;</p>
                                    </Col>
                                </Link>
                            </Row>
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </div>
    )
}

export default CompletedComponent;