import { Row, Col } from "react-bootstrap";
import "../../../styles/impossible.scss";
import { AIProject } from "./Model/AIModel";

const Impossible: React.FC<{ aiProjectData: AIProject }> = ({
  aiProjectData,
}) => {
  let quote;
  if (aiProjectData.name === "Air Thane") {
    quote = (
      <p className="quote">
        {aiProjectData?.iSubHeading1?.[0]} {aiProjectData?.iSubHeading1?.[2]}{" "}
        <span className="color">{aiProjectData?.iSubHeading1?.[3]} </span>
        <span style={{ display: "block", marginLeft: "10%" }}>
          {aiProjectData?.iSubHeading1?.[1]} {aiProjectData?.iSubHeading1?.[2]}{" "}
          <span className="color">{aiProjectData?.iSubHeading1?.[4]} </span>
        </span>
      </p>
    );
  } else if (aiProjectData.name === "Eiffel Tower") {
    quote = (
      <p className="quote">
        {aiProjectData?.iSubHeading1?.[0]}{" "}
        <span className="color">{aiProjectData?.iSubHeading1?.[2]}</span>,{" "}
        {aiProjectData?.iSubHeading1?.[0]}{" "}
        <span className="color">{aiProjectData?.iSubHeading1?.[3]}</span>
        <span style={{ display: "block", marginLeft: "10%" }}>
          {aiProjectData?.iSubHeading1?.[1]}{" "}
        </span>
        <span style={{ display: "block", marginLeft: "5%" }}>
          {aiProjectData?.iSubHeading1?.[0]}{" "}
          <span className="color">{aiProjectData?.iSubHeading1?.[4]} </span>
        </span>
      </p>
    );
  } else if (aiProjectData.name === "Dharavi") {
    quote = (
      <p className="quote">
        <span className="color">{aiProjectData?.iSubHeading1?.[0]} </span>
        {aiProjectData?.iSubHeading1?.[1]} {aiProjectData?.iSubHeading1?.[2]}{" "}
        <span style={{ display: "block", marginLeft: "10%" }}>
          <span className="color">{aiProjectData?.iSubHeading1?.[3]} </span>
          {aiProjectData?.iSubHeading1?.[1]} {aiProjectData?.iSubHeading1?.[2]}{" "}
        </span>
      </p>
    );
  } else if (aiProjectData.name === "The Albatross") {
    quote = (
      <p className="quote">
        <span className="color">{aiProjectData?.iSubHeading1?.[0]} </span>
        <span style={{ display: "block", marginLeft: "10%" }}>
          {aiProjectData?.iSubHeading1?.[1]}{" "}
        </span>
      </p>
    );
  } else {
    quote = (
      <p className="quote">
        {aiProjectData?.iSubHeading1?.[0]} {aiProjectData?.iSubHeading1?.[2]}{" "}
        <span className="color">{aiProjectData?.iSubHeading1?.[3]} </span>
        <span className="newLine">
          {aiProjectData?.iSubHeading1?.[1]}{" "}
          <span className="color">
            {aiProjectData?.iSubHeading1?.[2]}{" "}
            {aiProjectData?.iSubHeading1?.[4]}{" "}
          </span>
        </span>
      </p>
    );
  }
  return (
    <div className="projectImpossible">
      <Row>
        <Col className="imageSection">
          <img
            src={process.env.PUBLIC_URL + aiProjectData.iImageUrl}
            alt="Image"
            title="image"
          />
        </Col>
        <Col className="detailsSection">
          <h1>
            The <span>Im</span>possible Task
          </h1>
          {quote}
          <span className="heading1">{aiProjectData?.iHeading?.[0]}</span>
          <span className="heading2">{aiProjectData?.iHeading?.[1]}</span>
          <p>"{aiProjectData.iSubHeading2}"</p>
          <p>{aiProjectData.impossibleText}</p>
        </Col>
      </Row>
      <Row>
        <Col></Col>
      </Row>
    </div>
  );
};

export default Impossible;
