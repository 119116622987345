import React from 'react'
import HomeContentComponent from './HomeContentComponent'

const HomePageComponent = () => {
  return (
    <div>
      <HomeContentComponent />
    </div>
  )
}

export default HomePageComponent