import React, { useEffect, useState } from "react";
import { Tab, Nav, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { AIProject } from "../components/Projects/Tabs/Model/AIModel";
import NavBarComponent from "../components/NavBarComponent";
import Details from "../components/Projects/Tabs/Details";
import Achieved from "../components/Projects/Tabs/Achieved";

export const Dharavi = () => {
  const [projectMap, setProjectMap] = useState("projectMapImage font");
  const [navItemColor, setNavItemColor] = useState("white");
  const [navItemShadow, setNavItemShadow] = useState("2px 2px 4px black");
  const [navClass, setNavClass] = useState("desktopbottomNav");
  const [tabContent, setTabContent] = useState("desktopTabContents");
  const [tabContentBackground, settabContentBackground] = useState("black");
  const [hideTab, setTab] = useState("none");
  const [navBarBackground, setNavBarBackground] = useState("");
  const location = useLocation();
  const propsData = location.state as AIProject;
  const [backgroundImage, setbackgroundImage] = useState(
    process.env.PUBLIC_URL + propsData?.mImageUrl1
  );
  const handleNavClick = (tab: string) => {
    if (tab === "third") {
      setNavBarBackground("white");
      setProjectMap("projectMapWhite font");
      setNavItemColor("black");
      settabContentBackground("white");
    } else {
      setProjectMap("projectMapBlack font");
      setNavBarBackground("black");
      setNavItemColor("white");
      settabContentBackground("black");
    }
    setNavItemShadow("none");
    setTab("block");
    setbackgroundImage("none");
    setNavClass("mobilebottomNav");
    setTabContent("mobileTabContents");
  };
  useEffect(() => {
    function handleResize() {
      // Check if the window width is smaller than 991px
      if (window.innerWidth < 991) {
        setbackgroundImage(process.env.PUBLIC_URL + propsData?.mImageUrl2);
      } else {
        setbackgroundImage(process.env.PUBLIC_URL + propsData?.mImageUrl1);
      }
    }

    // Call handleResize initially to set the initial background image
    handleResize();

    // Add event listener to handle resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [propsData]);
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: navBarBackground,
      }}
      className="largeScreen"
    >
      <NavBarComponent backgroundColor={navBarBackground} />
      <Tab.Container id="left-tabs-example">
        <div
          style={{ backgroundColor: tabContentBackground }}
          className={tabContent}
        >
          <Tab.Content style={{ display: hideTab }}>
            <Tab.Pane eventKey="first">
              <Details aiProjectData={propsData} />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="projectImpossible">
                <Row>
                  <Col md={12} lg={6} className="imageSec">
                    <div className="d-block d-lg-none">
                      <h1>
                        <i>The</i> <span className="color">Im</span>possible{" "}
                        <i>Task</i>
                      </h1>
                      <div className="headQuote">
                        <div className="line1">
                          <span className="color">By the People</span> of
                          Dharavi
                        </div>
                        <div className="line2">
                          <span className="color">For the People</span> of
                          Dharavi
                        </div>
                      </div>
                      <span className="quoteHeading">
                        <span className="headingForImage">
                          {propsData.iHeading?.[0]}
                        </span>
                        <span className="iHeading">
                          {propsData.iHeading?.[1]}
                        </span>
                      </span>
                      <p style={{ textAlign: "center" }}>
                        "{propsData.iSubHeading2}"
                      </p>
                    </div>
                    <img
                      src={process.env.PUBLIC_URL + propsData.iImageUrl}
                      alt="Image"
                      title="image"
                    />
                  </Col>
                  <Col md={12} lg={6} className="detailsSection">
                    <div className="d-none d-lg-block">
                      <h1>
                        <i>The</i> <span className="color">Im</span>possible{" "}
                        <i>Task</i>
                      </h1>
                      <span className="headQuote">
                        <span className="color">By the People</span> of Dharavi
                        <span style={{ display: "block", marginLeft: "10%" }}>
                          <span className="color">For the People</span> of
                          Dharavi
                        </span>
                      </span>
                      <span className="quoteHeading">
                        <span className="headingForImage">
                          {propsData.iHeading?.[0]}
                        </span>
                        <span className="iHeading">
                          {propsData.iHeading?.[1]}
                        </span>
                      </span>
                      <p style={{ textAlign: "center" }}>
                        "{propsData.iSubHeading2}"
                      </p>
                    </div>

                    <p>
                      One of the most challenging Redevelopment Projects, the
                      World’s Largest Slum was taken up by Ameer Architects to
                      provide the people of Dharavi an unexploited design
                      suiting the Macro as well as Micro requirements of the
                      residents & it’s derived Sale Component.
                    </p>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <div className="projectAchieved">
                <Row>
                  <Col
                    md={12}
                    lg={6}
                    style={{
                      backgroundImage: `url(${propsData.aWatermarkUrl})`,
                    }}
                    className="detailSection"
                  >
                    <div className="backImage">
                      <div className="d-none d-lg-block">
                        <h1>
                          <i>The Task</i> <span className="color">A</span>
                          chieved
                        </h1>
                        <div className="aSubHeading1">
                          "{propsData?.aSubHeading1}"
                        </div>
                        <div className="aSubHeading2">
                          {propsData?.aSubHeading2}
                        </div>
                        <br />
                      </div>
                      <div className="achievedText">
                        <p>
                          “There have been numerous master plans for Dharavi,
                          but no architect has yet detailed a solution which has
                          looked into every existing obstacle of the DRP.”
                        </p>
                        <div>
                          Some of the impossible achieved by AA are as enlisted
                          below:
                        </div>
                        <div>
                          Rehab and Sale, both Designed with Structural Columns
                          within the overlapped and existing Plots & DP Remarks
                          Relocating tenants through meetings and discussion
                          towards various zones of the Rehab portion.
                        </div>
                        <div>
                          Respecting and maintaining Prime Religious structures
                        </div>
                        <div>Parking Zones</div>
                        <div>
                          Commercial Outlets and aligning them appropriately
                          through discussion and their projected business
                          formats.
                        </div>
                        <div>
                          Defining a Sale which would not be hampered by it’s
                          vicinity and consume the available FSI
                        </div>
                        <div>For more information, you may visit </div>
                        <div>
                          <a href="https://m.facebook.com/Dharavision-118915213299222/?ref=bookmarks">
                            https://m.facebook.com/Dharavision-118915213299222/?ref=bookmarks
                          </a>{" "}
                          or call 8484929377
                        </div>
                        <div>Public Announcements under Dharavision;</div>
                        <div>
                          SIGNED Letter of Commitment & Consent forms for
                          Residents to Join in.
                        </div>
                        <div>
                          <a href="https://www.facebook.com/permalink.php?story_fbid=125708979286512&id=118915213299222&__tn__=-R">
                            https://www.facebook.com/permalink.php?story_fbid=125708979286512&id=118915213299222&__tn__=-R
                          </a>
                        </div>
                        <div>
                          The Layout of a Rehabilitated Slum Apartment under
                          DHARAVISION.
                        </div>
                        <div>
                          <a href="https://www.facebook.com/118915213299222/photos/a.132369761953767/132411848616225/?type=3&theate">
                            https://www.facebook.com/118915213299222/photos/a.132369761953767/132411848616225/?type=3&theater
                          </a>
                        </div>
                        <div>
                          Why is Dharavi Not getting Redeveloped ? - Public
                          Statement DHARAVISION
                        </div>
                        <div>
                          <a href="https://www.facebook.com/118915213299222/photos/a.132369761953767/132369458620464/?type=3&theater">
                            https://www.facebook.com/118915213299222/photos/a.132369761953767/132369458620464/?type=3&theater
                          </a>
                        </div>
                        <div>What is DHARAVISION ?</div>
                        <div>
                          <a href="https://www.facebook.com/118915213299222/photos/a.132369761953767/132370231953720/?type=3&theater">
                            https://www.facebook.com/118915213299222/photos/a.132369761953767/132370231953720/?type=3&theater
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} lg={6} className="imageSection">
                    <div className="d-block d-lg-none">
                      <h1>The Task Achieved</h1>
                      <div className="aSubHeading1">
                        "{propsData?.aSubHeading1}"
                      </div>
                      <div className="aSubHeading2">
                        {propsData?.aSubHeading2}
                      </div>
                    </div>
                    <br />
                    <img src={propsData?.aImageUrl} alt="image" />
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
          </Tab.Content>
          <Nav className={navClass} variant="pills">
            <div>
              <h1 style={{ color: navItemColor, textShadow: navItemShadow }}>
                {propsData.name}
              </h1>
              <div className="nav-items">
                <Nav.Item>
                  <Nav.Link
                    style={{ color: navItemColor, textShadow: navItemShadow }}
                    eventKey="first"
                    onClick={() => handleNavClick("first")}
                  >
                    Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ color: navItemColor, textShadow: navItemShadow }}
                    eventKey="second"
                    onClick={() => handleNavClick("second")}
                  >
                    Impossible
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ color: navItemColor, textShadow: navItemShadow }}
                    eventKey="third"
                    onClick={() => handleNavClick("third")}
                  >
                    Achieved
                  </Nav.Link>
                </Nav.Item>
              </div>
            </div>
          </Nav>
        </div>
      </Tab.Container>
    </div>
  );
};
