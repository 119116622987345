import { Col, Nav, Row, Tab } from "react-bootstrap";
import NavBarComponent from "../components/NavBarComponent";
import { useLocation } from "react-router-dom";
import { AIProject } from "../components/Projects/Tabs/Model/AIModel";
import { useEffect, useState } from "react";
import "../styles/airThane.scss";
import Details from "../components/Projects/Tabs/Details";
import Achieved from "../components/Projects/Tabs/Achieved";

export const AirThane = () => {
  const [projectMap, setProjectMap] = useState("projectMapImage font");
  const [navItemColor, setNavItemColor] = useState("white");
  const [navItemShadow, setNavItemShadow] = useState("2px 2px 4px black");
  const [navClass, setNavClass] = useState("desktopbottomNav");
  const [tabContent, setTabContent] = useState("desktopTabContents");
  const [tabContentBackground, settabContentBackground] = useState("black");
  const [hideTab, setTab] = useState("none");
  const [navBarBackground, setNavBarBackground] = useState("");
  const location = useLocation();
  const propsData = location.state as AIProject;
  const [backgroundImage, setbackgroundImage] = useState(
    process.env.PUBLIC_URL + propsData?.mImageUrl1
  );
  const handleNavClick = (tab: string) => {
    if (tab === "third") {
      setNavBarBackground("white");
      setProjectMap("projectMapWhite font");
      setNavItemColor("black");
      settabContentBackground("white");
    } else {
      setProjectMap("projectMapBlack font");
      setNavBarBackground("black");
      setNavItemColor("white");
      settabContentBackground("black");
    }
    setNavItemShadow("none");
    setTab("block");
    setbackgroundImage("none");
    setNavClass("mobilebottomNav");
    setTabContent("mobileTabContents");
  };
  useEffect(() => {
    function handleResize() {
      // Check if the window width is smaller than 991px
      if (window.innerWidth < 991) {
        setbackgroundImage(process.env.PUBLIC_URL + propsData?.mImageUrl2);
      } else {
        setbackgroundImage(process.env.PUBLIC_URL + propsData?.mImageUrl1);
      }
    }

    // Call handleResize initially to set the initial background image
    handleResize();

    // Add event listener to handle resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [propsData]);
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: navBarBackground,
      }}
      className="largeScreen"
    >
      <NavBarComponent backgroundColor={navBarBackground} />
      <Tab.Container id="left-tabs-example">
        <div
          style={{ backgroundColor: tabContentBackground }}
          className={tabContent}
        >
          <Tab.Content style={{ display: hideTab }}>
            <Tab.Pane eventKey="first">
              <Details aiProjectData={propsData} />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="projectImpossible">
                <Row>
                  <Col md={12} lg={6} className="imageSec">
                    <div className="d-block d-lg-none">
                      <h1>
                        <i>The</i> <span className="color">Im</span>possible{" "}
                        <i>Task</i>
                      </h1>
                      <div className="headQuote">
                        <div className="line1">
                          Economic yet <span className="color">Iconic</span>{" "}
                        </div>
                        <div className="line2">
                          Designer yet{" "}
                          <span className="color">Construction friendly</span>
                        </div>
                      </div>
                      <span className="quoteHeading">
                        <span className="headingForImage">
                          {propsData.iHeading?.[0]}
                        </span>
                        <span className="iHeading">
                          {propsData.iHeading?.[1]}
                        </span>
                      </span>
                      <p style={{ textAlign: "center" }}>
                        "{propsData.iSubHeading2}"
                      </p>
                    </div>
                    <img
                      src={process.env.PUBLIC_URL + propsData.iImageUrl}
                      alt="Image"
                      title="image"
                    />
                  </Col>
                  <Col md={12} lg={6} className="detailsSection">
                    <div className="d-none d-lg-block">
                      <h1>
                        The <span>Im</span>possible Task
                      </h1>
                      <span className="headQuote">
                        Economic yet <span className="color">Iconic</span>
                        <span style={{ display: "block", marginLeft: "10%" }}>
                          Designer yet
                          <span className="color"> Construction friendly</span>
                        </span>
                      </span>
                      <span className="quoteHeading">
                        <span className="headingForImage">
                          {propsData.iHeading?.[0]}
                        </span>
                        <span className="iHeading">
                          {propsData.iHeading?.[1]}
                        </span>
                      </span>
                      <p style={{ textAlign: "center" }}>
                        "{propsData.iSubHeading2}"
                      </p>
                    </div>
                    <p>
                      AIR had to challenge the bigwigs of Real Estate by truly
                      offering what was claimed -{" "}
                      <span className="color">
                        Abundance of Light and Ventilation & Secured yet airy
                        landscapes
                      </span>{" "}
                      were just a few mandates in the design of{" "}
                      <span className="color">AIR.</span>
                    </p>
                    <p>
                      “All this within a{" "}
                      <span className="color">‘shapeless plot’</span> which
                      demanded centrally congested structures instead of a
                      spread-out airy development.”
                    </p>
                    <p>
                      AMEER Architects (AA) had the challenge to consume FSI in
                      an economical fashion, respect the oddly shaped open
                      spaces and yet make the project look seamless once
                      incepted.
                    </p>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <Achieved aiProjectData={propsData} />
            </Tab.Pane>
          </Tab.Content>
          <Nav className={navClass} variant="pills">
            <div>
              <h1 style={{ color: navItemColor, textShadow: navItemShadow }}>
                {propsData.name}
              </h1>
              <div className="nav-items">
                <Nav.Item>
                  <Nav.Link
                    style={{ color: navItemColor, textShadow: navItemShadow }}
                    eventKey="first"
                    onClick={() => handleNavClick("first")}
                  >
                    Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ color: navItemColor, textShadow: navItemShadow }}
                    eventKey="second"
                    onClick={() => handleNavClick("second")}
                  >
                    Impossible
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ color: navItemColor, textShadow: navItemShadow }}
                    eventKey="third"
                    onClick={() => handleNavClick("third")}
                  >
                    Achieved
                  </Nav.Link>
                </Nav.Item>
              </div>
            </div>
          </Nav>
        </div>
      </Tab.Container>
    </div>
  );
};
