import React from 'react'
import "../../styles/aboutuscontent.scss"
import { Col, Row, Stack } from 'react-bootstrap'
import "../../styles/global.scss"
import Image from "react-bootstrap/Image";


const AboutUsContentComponent = () => {
  return (
    <div >
      <Row className='about-us-row'>
        <Col sm={12} md={12} lg={6} className='about-us-content'>
          <Stack gap={3}>
            <div className='about-us-first-row'>Architect <span className='spanRed'>Ameer Chand</span>, an Internationally Renowned Architect has been operational in India since more than a decade. Starting <span>Ameer Architects</span> in 2013, the firm rather quickly gained the reputation of being known as a specialist in working on <span>AI</span> projects.</div>
            <div className='about-us-second-row'>We offer an array of Project-Centric services to achieve desired goals. Our clients ranging from Real Estate Developers to Institutional Conglomerates, benefit from our Concept of adopting a multi-faceted approach irrespective of our appointed role. Services offered are:</div>
            <div className="about-us-common-row"># Project Development</div>
            <div className="about-us-common-row"># Urban Design & Master Planning</div>
            <div className="about-us-common-row"># Architectural Design & Development</div>
            <div className="about-us-common-row"># Architectural Peer Review</div>
            <div className="about-us-seventh-row"><span className='spanRed'>AA</span> is an International Architectural firm - </div>
            <div className="about-us-eight-row">Specializing in Affordable Housing, High Rise & Urban Design</div>
          </Stack>
        </Col>
        <Col sm={12} md={12} lg={6}>
          <Stack gap={3}>
            {/* Image will come here */}
            <div className="image-container">
              <Image src={process.env.PUBLIC_URL + '/Assets/Ameer-pic.jpg'} className="ameer-pic" alt="Ameer Sir Pic" />
            </div>          
          </Stack>
        </Col>
      </Row>
    </div>
  )
}

export default AboutUsContentComponent