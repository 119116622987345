import { Button, Card, Carousel, Col, Row } from "react-bootstrap";
import "../../../styles/AIProjects.scss";
import { Link, useLocation } from "react-router-dom";
import { AIProject, aiModel } from "./Model/AIModel";

const AIProjectsComponent = () => {
  const location = useLocation();
  const propsData = location.state as AIProject;

  return (
    <div>
      <Carousel
        className="projectCarousel d-none d-lg-block"
        indicators={false}
        interval={null}
      >
        {Array.from({ length: Math.ceil(aiModel.length / 4) }).map(
          (_, index) => (
            <Carousel.Item key={index}>
              <Row className="card-center">
                {aiModel
                  .slice(index * 4, index * 4 + 4)
                  .map((project, cardIndex) => (
                    <Col key={cardIndex}>
                      <Link to={project?.link} state={project}>
                        <Card
                          className={
                            cardIndex % 2 === 0
                              ? "even bg-transparent"
                              : "odd bg-transparent"
                          }
                        >
                          <Card.Img
                            variant="top"
                            src={process.env.PUBLIC_URL + project?.cImageUrl}
                            alt="Card image"
                          />
                          <Card.Body>
                            <Card.Title>{project?.name}</Card.Title>
                            <Card.Text>
                              {project?.place}, {project?.city}, {project?.country}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  ))}
              </Row>
            </Carousel.Item>
          )
        )}
      </Carousel>
      <div className="mobileCard d-lg-none">
        {aiModel.map((data, index) => (
          <Card className="card" key={index}>
            <Card.Img
              variant="top"
              src={process.env.PUBLIC_URL + data?.cImageUrl}
              alt="Card image"
            />
            <Card.Body>
              <Row className="mobileRow">
                <Col xs={8} md={10}>
                  <Card.Title>{data?.name}</Card.Title>
                  <Card.Text>
                    {data?.place}, {data?.city}, {data?.country}
                  </Card.Text>
                </Col>
                <Link to={data?.link} state={data}>
                  <Col
                    className="d-flex justify-content-center align-items-center"
                    xs={4}
                    md={2}
                  >
                    <p>Explore &gt;</p>
                  </Col>
                </Link>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default AIProjectsComponent;
function useState(arg0: string): [any, any] {
  throw new Error("Function not implemented.");
}
