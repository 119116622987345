import React from "react";
import { Row, Col } from "react-bootstrap";
import { AIProject } from "./Model/AIModel";
import "../../../styles/details.scss";

const Details: React.FC<{ aiProjectData: AIProject }> = ({ aiProjectData }) => {
  return (
    <div className="projectDetails">
      <div className="detailsLarge d-none d-lg-block">
        <Row>
          <Col className="firstSection" md={12} lg={6}>
            <Row>
              <Col>
                <img
                  src={process.env.PUBLIC_URL + aiProjectData?.dImageUrl1}
                  alt=""
                />
              </Col>
            </Row>
            <Row>
              <Col className="detailContent">
                <span>Typology: {aiProjectData?.typology ?? "NA"}</span>
              </Col>
            </Row>
            <Row>
              <Col className="detailContent">
                <span>Client: {aiProjectData?.client ?? "NA"}</span>
              </Col>
            </Row>
            <Row>
              <Col className="detailContent">
                <span>Location: {aiProjectData?.location ?? "NA"}</span>
              </Col>
            </Row>
            <Row>
              <Col className="detailContent">
                <span>
                  Construction Area Levels: {aiProjectData?.levels ?? "NA"}
                </span>
              </Col>
            </Row>
          </Col>
          <Col className="secondSection" md={12} lg={6}>
            <img
              src={process.env.PUBLIC_URL + aiProjectData?.dImageUrl2}
              alt=""
            />
          </Col>
        </Row>
      </div>
      <div className="detailsMedium d-lg-none">
        <Row style={{ marginRight: 0 }}>
          <Col xs={12} sm={12} md={12} className="firstImage">
            <img
              src={process.env.PUBLIC_URL + aiProjectData?.dImageUrl1}
              alt=""
            />
          </Col>
          <Col xs={12} sm={12} md={12} className="details">
            <div>Typology: {aiProjectData?.typology ?? "NA"}</div>
            <div>Client: {aiProjectData?.client ?? "NA"}</div>
            <div>Location: {aiProjectData?.location ?? "NA"}</div>
            <div>Construction Area Levels: {aiProjectData?.levels ?? "NA"}</div>
          </Col>
          <Col xs={12} sm={12} md={12} className="secondImage">
            <img
              src={process.env.PUBLIC_URL + aiProjectData?.dImageUrl2}
              alt=""
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Details;
