import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import "../../styles/homecontent.scss"
import "../../styles/global.scss";
import "animate.css";

const HomeContentComponent = () => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        // Set the isActive state to true after the component has mounted
        setIsActive(true);
    }, []);
    return (
        <Container fluid className={`home-content ${isActive ? 'active' : ''}`}>
            <Row>
                <Col sm={12} md={12} lg={6}>
                    <div className="image-with-text">
                        <img
                            src={process.env.PUBLIC_URL + '/AA-LOGO-FULL-DARK-GREY.png'}
                            className="grey-img-logo"
                            alt="Ameer Architects GREY logo"
                        />
                        {/* <div className='text-inside-img animate__animated animate__slideInLeft'> */}
                        <div className='text-inside-img'>
                            Almost all of Ameer Architects projects had been deemed as an impossible feat, until it was materialized by us through an intricate and over the years developed approach which signifies our uniqueness. The skill lies in the fact that all our outcomes do not look forced or negotiated in terms of vision & sustainability.
                        </div>
                    </div>
                </Col>

                <Col sm={12} md={12} lg={6} className='home-content-right-col'>
                    <Stack gap={3}>
                        <div className='home-content-first' >“At Ameer Architects we pioneer the <span className='spanRed'>AI</span> approach”</div>
                        <div className='home-content-second'> & there is nothing <span className='spanRed'>A</span>rtificial about this <span className='spanRed'>I</span>ntelligence</div>

                        <div className='home-content-third'><span className='spanRed animate__animated animate__flash animate__infinite animate__slow'>AI</span> for us, stands for</div>
                        <div className='home-content-fourth'><span className='spanRed'>A</span>CHIEVING the <span className='spanRed'>I</span>MPOSSIBLE</div>
                    </Stack>
                </Col>
            </Row>
        </Container>
    )
}

export default HomeContentComponent;