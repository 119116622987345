import { Tabs, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { AIProject } from "./Model/AIModel";
import { useState } from "react";
import NavBarComponent from "../../NavBarComponent";
import "../../../styles/mapMobileView.scss";
import Details from "./Details";

const MapMobileView = () => {
  const location = useLocation();
  const propsData = location.state as AIProject;

  return (
    <div className="mobileMap">
      <NavBarComponent />
      <div className="title">{propsData.name}</div>
      <div className="tabs">
        <Tabs id="controlled-tab-example">
          <Tab eventKey="details" title="Details">
            <Details aiProjectData={propsData} />
          </Tab>
          <Tab eventKey="impossible" title="Impossible">
            Impossible
          </Tab>
          <Tab eventKey="achieved" title="Achieved">
            Achieved
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default MapMobileView;
