import React from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../styles/aicontent.scss';
import "../../styles/global.scss";
import { Link } from 'react-router-dom';
import { aiModel } from '../Projects/Tabs/Model/AIModel';


const AIContentComponent = () => {
  // console.log(aiModel)
  return (
    <Container fluid className="AIContent-main">
      <Row sm={12} md={12} lg={12}>
        <div className="AIContent-first-row">
          Our Ventured Typologies under - <span className='spanRed'>AI</span> Projects
        </div>
      </Row>
      <Row sm={12} md={12} lg={12} className='AIContent-second-row'>
        <div className="AIContent-second-one-row">
          <h1>
            <span className='h1Contain'>
              People&nbsp;
              <span className='spanRed'>
                Centric
              </span>
            </span>
            {/* <a href="/dharavi"> */}
            <Link to={"/dharavi"} state={aiModel[2]}>
              <span className='siteLocation'>
                Dharavi
                <span className='greyText'>
                  Mumbai, India
                </span>
              </span>
            </Link>

          {/* </a> */}
        </h1>
      </div>

      <div className="AIContent-third-one-row">
        <h1>
          <span className='h1Contain'>
            Plot&nbsp;
            <span className='spanRed'>
              Centric
            </span>
          </span>
          <Link to={"/the-albatross"} state={aiModel[3]}>
            <span className='siteLocation'>
              The Albatross 
              <span className='greyText'>
                Chembur, Mumbai, India
              </span>
            </span>
          </Link>
        </h1>
      </div>

      <div className="AIContent-second-one-row">
        <h1>
          <span className='h1Contain'>
            Structure&nbsp;
            <span className='spanRed'>
              Centric
            </span>
          </span>
          <Link to={"/eiffel-tower"} state={aiModel[1]}>
            <span className='siteLocation'>
              Eiffel Tower
              <span className='greyText'>
                Mazgaon, Mumbai, India
              </span>
            </span>
          </Link>
        </h1>
      </div>

      <div className="AIContent-third-one-row align-center">
        <h1>
          <span className='h1Contain'>
            Business&nbsp;
            <span className='spanRed'>
              Centric
            </span>
          </span>
          <Link to={"/air-thane"} state={aiModel[0]}>
            <span className='siteLocation'>
              AIR Thane Thane
              <span className='greyText'>
                Mumbai, India
              </span>
            </span>
          </Link>
        </h1>
      </div>
    </Row>
    </Container >
  );
};

export default AIContentComponent;
