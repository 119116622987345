import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import NavBarComponent from '../components/NavBarComponent';
import AboutUsContentComponent from '../components/AboutUs/AboutUsContentComponent';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion'

const AboutUs = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <NavBarComponent />
            <Container fluid>
                <AboutUsContentComponent />
            </Container>
        </motion.div>

    )
}

export default AboutUs