export interface OnGoingProjectsInterface {
  name: string;
  place: string;
  city: string;
  cImageUrl: string;
  dImageUrl1: string;
  country: string;
  typology: string;
  client: string;
  levels: string;
  desc: string[] | null;
  constructionArea: string | null;
  location: string;
}
export const onGoingModel: OnGoingProjectsInterface[] = [
    {
        name: 'Worli Tower',
        place: 'Worli',
        city: 'Mumbai',
        cImageUrl: '/Assets/Projects/OnGoing/worliCarousel.jpg',
        country: 'India',
        typology: 'Residential Skyscraper',
        client: 'Starlight Hospitality',
        levels: 'Basement + 5Podium + 35 floors',
        desc: ['Located at one the most sorted out locations in Mumbai, the tower stands on the banks of Arabian Sea. Comprising of super luxury Duplex apartments, I have designed the structure with amenities on various split levels in the form of Business Lounges, Restaurants, Spa’s etc. The form has been carefully designed understanding that although the frontage of the structure is important, the city as such would view the rear-side of this skyscraper. Most of the sea facing buildings in Mumbai overlook this fact and lose on aesthetics. Currently in design stage, a first –cut view is attached.'],
        dImageUrl1: "/Assets/Projects/OnGoing/WorliTower/WorliTower.png",
        constructionArea : "",
        location : ""
    },
    {
        name: 'Ananta Towers',
        place: 'Thane',
        city: 'Mumbai',
        cImageUrl: '/Assets/Projects/OnGoing/anantaCarousel.jpg',
        country: 'India',
        typology: 'Mixed use',
        client: 'Sagar Developer',
        levels: 'Basement + Podium + 72 floors',
        desc: [
            'Ananta Towers is located at Thane, it delivers a Luxurious Living Experience for a lifetime with our enormous creation to the local, developed in the hub of the megacity. Ananta is a 72 storey tower, constructed with an ample of amenities to provide a luxury home in the heart of the city.'
        ],
        dImageUrl1: "/Assets/Projects/OnGoing/AnantaTowers/Ananta.jpg",
        constructionArea : "",
        location : "Thane, Mumbai, India"
    },
    {
        name: 'August House',
        place: 'Andheri',
        city: 'Mumbai',
        cImageUrl: '/Assets/Projects/OnGoing/augustCarousel.jpg',
        country: 'India',
        typology: 'Commercial',
        client: 'Dheeraj Realty',
        levels: '2 Podium + 8 floors',
        desc: [
            'Located at one of the most sort after locations in the suburbs of Mumbai, August House is a Commercial Complex 5 minutes from the Mumbai International Airport and at hands reach from the Western Express Highway.',
            'The basic thought process in designing this structure was to create a landmark structure, with a simplistic approach. Basic forms were adopted by me to device this form. Further, not losing onto valuable permissible floor plate area’s are crucial in the city dominated by FSI and height restriction. Since every project out here have restricted X,Y & Z axis, designing interesting elevations are quite challenging, where reducing floor plates too isn’t an option',
            'The proximity of this structure to the international airport made me device a Terrace which would leave an impact on every flyer as seen airborne'
        ],
        dImageUrl1: "/Assets/Projects/OnGoing/AugustHouse/AugustHouse.png",
        constructionArea : "",
        location : "Thane, Mumbai, India"
    },
    {
        name: 'Views Tower',
        place: 'Chembur',
        city: 'Mumbai',
        cImageUrl: '/Assets/Projects/OnGoing/viewTowerCarousel.png',
        country: 'India',
        typology: 'Mixed use',
        client: 'RKW Developer',
        levels: 'Stilt + 17 floors',
        desc: [
            'Located bank opposite the Chembur Golf Club, is one of the largest Residential composite developments in Mumbai. A Mixed-Use structure, has 3 BHK , 2.5 BHK and 2BHK apartments designed in a peripheral format to optimize their individual views.',
            'A part of SRA, Slum Rehabilitation Authority, we are currently executing the Rehab Structure, which when physically executed provides FSI for your sale component. These projects are always complex and require a knowledge of various Municipal facets to develop design. Specially collective services and mode of construction.'
        ],
        dImageUrl1: "/Assets/Projects/OnGoing/ViewsTower/ViewsTower.png",
        constructionArea : "",
        location : "Thane, Mumbai, India"
    },
];