import React from 'react'
import { Col, Row, Stack } from 'react-bootstrap'
import "../../styles/contactuscontent.scss"
import { Envelope } from 'react-bootstrap-icons'

const ContactUsContentComponent = () => {
    return (
        <Row>
            {/* 1 of 2 */}
            <Col sm={12} md={6} lg={6} className="contactUs-col1">
                <Stack gap={3}>
                    <div className="contactUs-title">Contact Us</div>
                    <div className='contactUs-content'>For all project related queries, business inquiries and competitions please reach out to us at</div>
                    <div className="contactUs-content">
                        <Envelope></Envelope>  contact@ameerarchitects.com
                    </div>
                    {/* <div className="contactUs-content">
                        <Telephone></Telephone> + 022-XXXX XXXX
                    </div> */}
                </Stack>
                <br ></br>
                <div className="contactUs-title">Careers</div>
                <br></br>

                <Stack gap={2} >
                    <div className='contactUs-content'>Visit this space for our upcoming Job Openings</div>
                </Stack>
            </Col>
        </Row>
    )
}

export default ContactUsContentComponent